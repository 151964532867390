<template>
  <div class="gender-consumption-statistics">
    <ChartTitle :showBorder="false" :getChart="getChart" />
    <div class="flex justify-center">
      <DonutChartBlock
        :options="getOptions(chartData.sumAmount)"
        title="性別總消費金額"
        :subtitle="`$ ${sumValue(chartData.sumAmount)}`"
        class="flex-1"
      />
      <DonutChartBlock
        :options="getOptions(chartData.count)"
        title="性別總消費單數"
        :subtitle="`${sumValue(chartData.count)}單`"
        class="flex-1"
      />
    </div>
    <div class="flex justify-center">
      <DonutChartBlock
        :options="getOptions(chartData.avgAmount)"
        title="性別平均消費金額"
        class="flex-1"
      />
      <DonutChartBlock
        :options="getOptions(chartData.avgMemberCount)"
        title="性別平均消費單數"
        class="flex-1"
      />
    </div>
    <div class="flex justify-end">
      <p
        class="flex items-center text-primary-100 cursor-pointer"
        @click="$router.push({ name: 'MemberGanderOverview' })"
      >
        看更多
        <img style="margin: auto" width="18" :src="ArrowRight">
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import DonutChartBlock from '@/components/Dashboard/DonutChartBlock.vue'
import { getGenderData, generateGenderConfig } from '../../common'
import { truncateDecimal } from '@/utils/dashboard'
import store from '@/store'
import ArrowRight from '@/assets/operateDashboard/arrow-right.svg'
import { useOrg } from '@/use/useOrg'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'GenderConsumptionStatistics',
  components: { ChartTitle, DonutChartBlock },
  setup () {
    const { shopId } = useShop()
    const { orgId } = useOrg()

    const chartData = reactive({
      sumAmount: [],
      count: [],
      avgAmount: [],
      avgMemberCount: [],
    })

    const genderLabels = {
      male: '男',
      female: '女',
      other: '其他',
    }

    const getOptions = (series) => {
      return generateGenderConfig({
        labels: Object.values(genderLabels),
        series: series || [],
      })
    }

    const sumValue = (array = []) => {
      const res = array.reduce((prev, curr) => {
        return prev + curr
      }, 0)
      return truncateDecimal(res)
    }

    const getChart = async ({ interval }) => {
      const res = await getGenderData({
        orgId: orgId.value,
        shopId: shopId.value,
        interval,
        genderLabels,
      })

      chartData.sumAmount = res.sumAmount
      chartData.count = res.count
      chartData.avgAmount = res.avgAmount
      chartData.avgMemberCount = res.avgMemberCount
    }

    return {
      chartData,
      getOptions,
      sumValue,
      getChart,
      ArrowRight,
    }
  },
})
</script>

<style lang="postcss" scoped>
.gender-consumption-statistics {
  @apply flex flex-col gap-5;
}
</style>
