<template>
  <div class="first-purchase-rate-statistics">
    <BubbleChartBlock
      :showOptions="true"
      :getChart="getChart"
      title="首購 V.S 回購 總消費金額"
      hint="您可以查看首購與回購者的總消費金額（y軸），圓圈的範圍代表人數多寡，圓圈越大代表人數越多、圓圈越小代表人數越少"
      :options="getOptions('sumAmount')"
    />
    <BubbleChartBlock
      title="首購 V.S 回購 平均消費金額"
      hint="您可以查看首購與回購者的平均消費金額（y軸），此指的是每一位消費會員的平均客單價。圓圈的範圍代表人數多寡，圓圈越大代表人數越多、圓圈越小代表人數越少"
      :options="getOptions('avgAmount')"
    />
    <BubbleChartBlock
      title="首購 V.S 回購 總消費單數"
      hint="您可以查看首購與回購者的總消費單數（y軸），圓圈的範圍代表人數多寡，圓圈越大代表人數越多、圓圈越小代表人數越少"
      :options="getOptions('count')"
    />
    <BubbleChartBlock
      title="首購 V.S 回購 平均消費單數"
      hint="您可以查看首購與回購者的平均消費單數（y軸），圓圈範圍代表人數多寡，圓圈越大代表人數越多、圓圈越小代表人數越少"
      :options="getOptions('avgMemberCount')"
    />

    <div class="flex justify-end">
      <p
        class="flex items-center text-primary-100 cursor-pointer"
        @click="$router.push({ name: 'FirstPurchaseOverview' })"
      >
        看更多
        <img style="margin: auto" width="18" :src="ArrowRight">
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import store from '@/store'
import BubbleChartBlock from '@/components/Dashboard/BubbleChartBlock.vue'
import {
  getFirstPurchaseData,
  generateFirstPurchaseConfig,
  yaxisTitleConfig,
} from '../../common'
import { truncateDecimal } from '@/utils/dashboard'
import ArrowRight from '@/assets/operateDashboard/arrow-right.svg'
import { useOrg } from '@/use/useOrg'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'FirstPurchaseRateStatistics',
  components: { BubbleChartBlock },
  emits: ['getTime'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { orgId } = useOrg()

    const chartData = reactive({
      firstPurchase: {
        name: '首購',
        data: [],
      },
      repurchase: {
        name: '回購',
        data: [],
      },
    })

    const getOptions = (target) => {
      return generateFirstPurchaseConfig(
        [
          {
            name: '首購',
            data: chartData.firstPurchase.data.map((item) => [
              item.createdAt,
              truncateDecimal(item[target]),
              item.memberCount,
            ]),
          },
          {
            name: '回購',
            data: chartData.repurchase.data.map((item) => [
              item.createdAt,
              truncateDecimal(item[target]),
              item.memberCount,
            ]),
          },
        ],
        yaxisTitleConfig[target],
      )
    }

    const getChart = async ({ interval, splitType }) => {
      const { firstPurchase, repurchase, lastUpdatedAt } =
        await getFirstPurchaseData({
          orgId: orgId.value,
          shopId: shopId.value,
          interval,
          splitType,
        })
      emit('getTime', lastUpdatedAt)
      chartData.firstPurchase.data = firstPurchase
      chartData.repurchase.data = repurchase
    }
    return {
      getOptions,
      getChart,
      ArrowRight,
    }
  },
})
</script>

<style lang="postcss" scoped>
.first-purchase-rate-statistics {
  @apply flex flex-col gap-5;
}
</style>
